<template>
  <div>
    Oracles {{ oracles }}<br><br>
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex'
  export default {
    name: 'Oracles',
    data () {
      return {
        oracles: {},
      }
    },
    computed: {
      ...mapState('platform', ['mainHost']),
    },
    async mounted () {
      var presponse = await this.$http.get(this.mainHost + '/oracles')
      console.log('oracles', presponse.data)
      this.oracles = presponse.data
    },
  }
</script>
